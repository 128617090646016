import React from 'react';
import { motion } from 'framer-motion';
import img1 from '../assets/img1.png';
import img2 from '../assets/img2.png';
import img3 from '../assets/img3.png';
import img4 from '../assets/img4.png';
import img5 from '../assets/img5.jpg';
import img6 from '../assets/img6.jpg';
import img7 from '../assets/larry1.jpg';
import img8 from '../assets/larry2.jpg';
import img9 from '../assets/market.jpg';
import img10 from '../assets/oldportrait.jpg';
import img11 from '../assets/seattletimeshonor.jpg';
import img12 from '../assets/oldcomplex1.jpg';
import img13 from '../assets/newspaper.jpg';
import img14 from '../assets/larryfamily.jpg';
import img15 from '../assets/60family.jpg';
import img16 from '../assets/longtimecustomers.jpg';
import img17 from '../assets/oldcomplex.jpg';
import img18 from '../assets/fadehaircut.png';
const images = [
  { src: img1, subtitle: 'Former Seattle Seahawks Running Back Thomas Rawls getting a haircut' },
  { src: img2, subtitle: 'Get a customised design designed specifically for you' },
  { src: img3, subtitle: 'Trendy kids design to make them look at their very best' },
  { src: img4, subtitle: 'Elegant Beard Trim and Shave' },
  { src: img5, subtitle: 'Our Shop' },
  { src: img6, subtitle: '60th Anniversary of our Barbershop' },
  { src: img7, subtitle: 'Larry, the founder of our barbershop' },
  { src: img8, subtitle: 'Larry, the founder of our barbershop' },
  { src: img9, subtitle: 'The oldern times of our barbershop' },
  { src: img10, subtitle: 'Old Picture of Larry' },
  { src: img11, subtitle: 'Seattle Times honoring barbershop Founder' },
  { src: img12, subtitle: 'Old Complex of our barbershop' },
  { src: img13, subtitle: ' Newspaper highlighting our barbershop' },
  { src: img14, subtitle: "Larry's family" },
  { src: img15, subtitle: "60th Anniversary of our Barbershop with Larry's family" },
  { src: img16, subtitle: 'Long time customers of our barbershop' },  
  { src: img17, subtitle: 'Old Complex of our barbershop' },
  { src: img18, subtitle: 'Fade Haircut' },
];

const Gallery = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-4xl font-bold mb-4 text-center">Gallery</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {images.map((image, index) => (
          <motion.div
            key={index}
            className="border"
            whileHover={{ scale: 1.1, translateZ: 50, perspective: 1000, boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)", transition: { duration: 0.2 } }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <img src={image.src} alt={`Gallery ${index}`} className="w-full h-64 object-cover" />
            <div className="bg-black bg-opacity-50 text-white p-2 text-center">
              {image.subtitle}
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
